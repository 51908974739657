<div class="modal-body home-modal-bdy permission-req-modal">
  <div class="meting-modal">
    <h5 class="mb-1"><strong>Permissions Required</strong></h5>
    <div class="subheading">Sed ut perspiciatis unde omnis iste natus error sit</div>
  </div>
  <div class="d-flex justify-content-around micParent">
    <img class="permission-mic" src="../../assets/microphone.svg" alt="mic-icon" />
    <div class="pl-3">
      <h6 class="mb-1"><strong>Mic</strong></h6>
      <p>Sed ut perspiciatis unde omnis iste natus error sit</p>
    </div>
  </div>
  <div class="d-flex justify-content-around camParent">
    <img class="permission-vid" src="../../assets/perVideo.svg" />
    <div class="pl-3">
      <h6 class="mb-1"><strong>Camera</strong></h6>
      <p>Sed ut perspiciatis unde omnis iste natus error sit</p>
    </div>
  </div>
  <div class="home-modal-btns text-center mt-5">
    <button class="btn w-100 skyblueBtn" aria-label="Close" (click)="confirmMessge(true)">Allow All Access</button
    ><br />
    <button class="btn cancelBtn w-100" aria-label="Close" (click)="confirmMessge(false)">Cancel</button>
  </div>
</div>
