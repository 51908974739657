import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from '@app/services/auth.service';
import { LoginComponent } from '../../auth/login.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-front-header',
  templateUrl: './front-header.component.html',
  styleUrls: ['./front-header.component.scss'],
})
export class FrontHeaderComponent implements OnInit {
  modalRef: BsModalRef;
  menuHidden = true;
  isTokenExpire = false;

  constructor(private router: Router, private authService: AuthService, private modalService: BsModalService) {}

  ngOnInit() {
    this.isTokenExpire = this.authService.isTokenExpired();
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  get username(): string | null {
    const credentials = this.authService.getProfileFromToken();
    return credentials ? credentials.email : null;
  }
  logIn(id: any) {
    const subject = new Subject<any>();
    this.modalRef = this.modalService.show(LoginComponent, {
      class: 'modal-lg-login modal-dialog-centered signupModal',
      initialState: { id: id },
    });
    this.modalRef.content.subject = subject;
    subject.asObservable().subscribe((data) => {
      if (data === true) {
        this.modalRef.hide();
      }
    });
  }
}
