import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import { Logger, UntilDestroy } from '@core';
import { AuthService } from '@app/services/auth.service';
import { emailRegex } from '../helpers/index';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

const log = new Logger('Login');
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  subject: Subject<boolean>;
  id: any;
  pass: boolean;
  loading = false;
  loginForm!: FormGroup;

  isSubmitted = false;
  type = false;
  isForgot = false;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.createForm();
  }
  ngOnInit() {}
  login() {
    this.isSubmitted = true;
    if (!this.loginForm.valid) {
      return;
    }
    this.loading = true;
    this.authService.login(this.loginForm.value).then((response) => {
      if (response === undefined) {
        this.loading = false;
        return;
      } else {
        this.loading = false;

        this.subject.next(true);
      }
    });
  }
  get f() {
    return this.loginForm.controls;
  }
  private createForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(emailRegex)]],
      password: ['', Validators.required],
      remember: true,
    });
  }
  showPassword() {
    this.type = !this.type;
  }
  forgot() {
    this.isForgot = true;
  }
  getId(id: any) {
    this.id = id;
  }
  getPass(pass: boolean) {
    this.subject.next(pass);
  }
  signUp(id: any) {
    this.id = id;
  }
  backLogin() {
    this.isForgot = false;
  }
}
