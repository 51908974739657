import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { VideochatService } from '../../services/videochat.service';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss'],
})
export class PreferencesComponent implements OnInit {
  subject: Subject<boolean>;
  isSubmitted = false;
  name = '';
  submitted = false;
  constructor(private videoChatService: VideochatService) {}

  ngOnInit(): void {
    var profile = JSON.parse(localStorage.getItem('profile'));
    this.name = profile.fullName;
  }
  confirmMessge(bool: boolean) {
    this.subject.next(bool);
    this.subject.complete();
  }
  submit(form: NgForm) {
    this.submitted = true;
    if (!form.valid) {
      return;
    }
    this.subject.next(true);
    this.subject.complete();
  }
}
