import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@env/environment';
import { CoreModule } from '@core';
import { SharedModule } from '@shared';
import { AuthModule } from '@app/auth';
import { HomeModule } from './home/home.module';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MeetingComponent } from './meeting/meeting.component';
import { ChatComponent } from './chat/chat.component';
import { ModalModule, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FrontHeaderComponent } from './dashboard/front-header/front-header.component';
import { PermissionComponent } from './modals/permission/permission.component';
import { PreferencesComponent } from './modals/preferences/preferences.component';
import { CommonModule } from '@angular/common';
import { SetupProfileComponent } from './setup-profile/setup-profile.component';
import { MeetingLeftComponent } from './meeting-left/meeting-left.component';
import { KinderMeetingComponent } from './kinder-meeting/kinder-meeting.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { InviteUserComponent } from './modals/invite-user/invite-user.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const config: SocketIoConfig = { url: environment.socketUrl, options: { autoConnect: false } };
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    NgbModule,
    CoreModule,
    SharedModule,
    ShellModule,
    HomeModule,
    AuthModule,
    CommonModule,
    ReactiveFormsModule,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    SocketIoModule.forRoot(config),
    // VideoCallingModule
    // ToastrModule.forRoot({
    //   timeOut: 3000,
    //   positionClass: 'toast-top-right',
    //   preventDuplicates: true,
    // }),
  ],
  declarations: [
    AppComponent,
    MeetingComponent,
    ChatComponent,
    DashboardComponent,
    FrontHeaderComponent,
    PermissionComponent,
    PreferencesComponent,
    SetupProfileComponent,
    MeetingLeftComponent,
    KinderMeetingComponent,
    InviteUserComponent,
    PageNotFoundComponent,
  ],
  entryComponents: [PermissionComponent, PreferencesComponent, InviteUserComponent],
  providers: [BsModalRef],
  bootstrap: [AppComponent],
})
export class AppModule {}
