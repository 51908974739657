export function registerMutation(obj: any) {
  return `
    mutation {
      register(
        firstName: "${obj.firstName}"
        lastName: "${obj.lastName}"
        dob: "${obj.year}-${obj.monthNo}-${obj.day}"
        email: "${obj.email}"
        password: "${obj.password}"
      ) {
        user {
          id
          email
          firstName
          lastName
          isActive
        }
      }
    }`;
}
export function logInMutation(obj: any) {
  return `
    mutation {
      login(
        email: "${obj.email}"
        password: "${obj.password}"
      ) {
        token
        user {
          id
          email
          fullName
          firstName
          lastName
          profilePicUrl
          firstLanguage
          role
          isActive
        }
      }
    }`;
}
export function onBoardingMutation(obj: any) {
  return `
  mutation{
    onBoardUser(id: ${obj.id} 
      presonalAddress:{
      address:"${obj.presonalAddress.address}"
      country:"${obj.presonalAddress.country}"
      state:"${obj.presonalAddress.state}"
      postal_code:${obj.presonalAddress.postal_code}
      type:"${obj.presonalAddress.type}"
    }
    billingAddress:{
       address:"${obj.billingAddress.address}"
      country:"${obj.billingAddress.country}"
      state:"${obj.billingAddress.state}"
      postal_code:${obj.billingAddress.postal_code}
      type:"${obj.billingAddress.type}"
    }
    fullName:"${obj.fullName}"
    gender:"${obj.gender}"
    profilePicUrl:"${obj.profilePicUrl}"
    role:"${obj.role}"
    firstLanguage:"${obj.firstLanguage}"
    otherLanguage:"${obj.otherLanguage}"
    privacySetting:"${obj.privacySetting}"
    contactPreferences:"${obj.contactPreferences}"){
      token
      user{
        id
    firstName
    lastName
    fullName
    firstLanguage
    profilePicUrl
    role
    email
    isActive
      }
    }
  }`;
}
