<div class="col-12 m-auto signUp-main">
  <h3>Sign Up to get started</h3>
  <form class="form-padding" [formGroup]="signupForm" (ngSubmit)="submitForm()">
    <div class="form-group email-input">
      <label for="email">Email Address</label>
      <div class="input-group">
        <input type="text" formControlName="email" placeholder="Email Address" class="form-control" />
      </div>
      <div class="input-error" *ngIf="f.email.errors && f.email.errors.required && isSubmitted">Email is required.</div>
      <div class="input-error" *ngIf="f.email.errors && f.email.errors.pattern && isSubmitted">
        Enter a valid email.
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <div class="form-group email-input">
          <label for="firstName">First Name</label>
          <div class="input-group">
            <input type="text" formControlName="firstName" placeholder="First Name" class="form-control" />
          </div>
          <div class="input-error" *ngIf="f.firstName.errors && f.firstName.errors.required && isSubmitted">
            First name is required.
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <div class="form-group email-input">
          <label for="lastName">Last Name</label>
          <div class="input-group">
            <input type="text" formControlName="lastName" placeholder="Last Name" class="form-control" />
          </div>
          <div class="input-error" *ngIf="f.lastName.errors && f.lastName.errors.required && isSubmitted">
            Last name is required.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group email-input">
      <label for="email">Create Password</label>
      <div class="input-group">
        <input
          [type]="show ? 'text' : 'password'"
          formControlName="password"
          placeholder="Create Password"
          class="form-control"
        />
        <img src="../../../assets/eye.png" (click)="showPass()" />
      </div>
      <div class="input-error" *ngIf="f.password.errors && f.password.errors.required && isSubmitted">
        Password is required.
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <label>Date of Birth</label>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="form-group email-input">
          <label for="firstName">Year</label>
          <div class="input-group">
            <select class="form-control" formControlName="year" (change)="changeYear($event)">
              <option value="">yyyy</option>
              <option *ngFor="let year of years">
                {{ year }}
              </option>
            </select>
            <div class="input-error" *ngIf="f.year.errors && f.year.errors.required && isSubmitted">
              Year is required.
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="form-group email-input">
          <label for="firstName">Month</label>
          <div class="input-group" (change)="changeMonth($event)">
            <select class="form-control" name="Month" formControlName="month">
              <option value="">Month</option>
              <option *ngFor="let month of monthNames">
                {{ month }}
              </option>
            </select>
            <div class="input-error" *ngIf="f.month.errors && f.month.errors.required && isSubmitted">
              Month is required.
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="form-group email-input">
          <label for="firstName">Day</label>
          <div class="input-group">
            <select class="form-control" formControlName="day" name="DD">
              <option value="">DD</option>
              <option *ngFor="let day of days">
                {{ day }}
              </option>
            </select>
            <div class="input-error" *ngIf="f.day.errors && f.day.errors.required && isSubmitted">Day is required.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="signup-prvcy-txt pb-3 pt-4">By signing up you agree to our <a href="#">Terms & Privacy Policy</a></div>

    <button class="btn sign-up-btn">
      <span
        *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"
        style="color: white; margin-bottom: 5px"
      ></span
      >Sign Up
    </button>

    <div class="arledy-signup-txt text-center pt-3">
      Already have an account? <a (click)="logIn('logIn')"> Sign In</a>
    </div>
  </form>
</div>
