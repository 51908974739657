import { Injectable } from '@angular/core';
import { axiosPost, axiosUploadFile } from './api';
import { registerMutation, logInMutation, onBoardingMutation } from '../mutations/auth';
import { localStorageKeys } from '../helpers/index';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ChatService } from './chat.service';
import { VideochatService } from '../services/videochat.service';

const jwtHelper = new JwtHelperService();
@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private chatService: ChatService,
    private videoChatService: VideochatService
  ) {}

  register(formValue: any) {
    try {
      return axiosPost(registerMutation(formValue)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.register && response.data.data.register.user) {
          // this.setToken(response.data.data.register.token);
          this.setUser(JSON.stringify(response.data.data.register.user));
          // this.chatService.getChatToken(response.data.data.register.user.email);
          this.toastr.success('Sign up successfully');
          // this.router.navigate(['/home']);
          return response;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  setToken(token: any) {
    localStorage.setItem(localStorageKeys.Token, token);
  }
  setUser(user: any) {
    localStorage.setItem(localStorageKeys.Profile, user);
  }
  getProfileFromToken() {
    const token = this.getToken();
    let profile = JSON.stringify(jwtHelper.decodeToken(token));
    return profile ? JSON.parse(profile) : {};
  }

  isLogin() {
    const token = this.getToken();
    return !!token;
  }
  getToken() {
    return localStorage.getItem(localStorageKeys.Token);
  }

  isTokenExpired() {
    let token = this.getToken();
    if (!token) return true;

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    return date.valueOf() < new Date().valueOf();
  }

  isChatTokenExpired() {
    let token = localStorage.getItem('twilioChatToken');
    if (!token) return true;

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    return date.valueOf() < new Date().valueOf();
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwtHelper.decodeToken(token);

    if (decoded.exp === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  logout() {
    localStorage.removeItem(localStorageKeys.Token);
    localStorage.removeItem(localStorageKeys.TwilioChatToken);
    localStorage.removeItem(localStorageKeys.TwilioVideoToken);
    localStorage.removeItem(localStorageKeys.Profile);
  }

  login(formValue: any) {
    try {
      return axiosPost(logInMutation(formValue)).then(async (response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        // if(response.status !==200) return;
        if (response.data.data.login && response.data.data.login.user && response.data.data.login.user.fullName) {
          this.setToken(response.data.data.login.token);
          this.setUser(JSON.stringify(response.data.data.login.user));
          let fullIdentity = `${response.data.data.login.user.email}-${response.data.data.login.user.fullName}`;
          this.chatService.getChatToken(fullIdentity);
          this.router.navigate(['/home']);
          this.toastr.success('Login Successfully.');
          return response;
        } else {
          this.setUser(JSON.stringify(response.data.data.login.user));
          this.router.navigate(['/setup-profile']);
          return response;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  onBoardingUser(formValue: any) {
    try {
      return axiosPost(onBoardingMutation(formValue)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.onBoardUser && response.data.data.onBoardUser.token) {
          localStorage.removeItem('profile');
          this.setUser(JSON.stringify(response.data.data.onBoardUser.user));
          this.setToken(response.data.data.onBoardUser.token);
          let fullIdentity = `${response.data.data.onBoardUser.user.email}-${response.data.data.onBoardUser.user.fullName}`;
          this.chatService.getChatToken(fullIdentity);
          this.router.navigate(['/home']);
          return response.data.data.onBoardUser;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  uploadImage(formData: any) {
    try {
      return axiosUploadFile(formData);
    } catch (error) {}
  }
}
