export function getAllMeetings() {
  return `
  query{
    getAllMeetings{
      id
      meetingId
      userId
      title
      startTime
      User{
        id
        fullName
        email
      }
      durationTime{
        hour
        minutes
      }
      createdAt
      attendees{
        email
        id
        meetingId
      }
    }
  }`;
}
export function getMeetingByMeetingId(id: any) {
  return `
  query{
    getMeetingByMeetingId (id: ${id}){
      id
      meetingId
      userId
      title
      startTime
      User{
        id
        fullName
        email
      }
      durationTime{
        hour
        minutes
      }
      createdAt
      attendees{
        email
        id
        meetingId
      }
    }
  }`;
}
export function getUserByMeetingId(id: any) {
  return `
  query{
    getUserByMeetingId(meetingId:"${id}"){
     id
     fullName
     email
     profilePicUrl
   }
   }`;
}
export function getUserPermissionsByMeetingId(id: any) {
  return `
  query{
    getUserPermissionsByMeetingId(meetingId:"${id}"){
      id
      email
      meetingId
      permissions{
        mute
        video
        muteAll
      }
    }
  }`;
}
export function getMeetingsByUserId(email: any) {
  return `
  query{
    getMeetingsByUserId(email:"${email}"){
    id
      meetingId
      userId
      title
      startTime
      User{
        id
        firstName
        lastName
        fullName
        email
      
      }
      durationTime{
        hour
        minutes
      }
      createdAt
      updatedAt
    }
  }
  `;
}
