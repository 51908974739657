import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-meeting-left',
  templateUrl: './meeting-left.component.html',
  styleUrls: ['./meeting-left.component.scss'],
})
export class MeetingLeftComponent implements OnInit {
  paramId: any;
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.paramId = params.id;
    });
  }
  reJoin() {
    this.router.navigate(['/meet', this.paramId]);
  }
  cancel() {
    this.router.navigate(['/home']);
  }
}
