import { Injectable } from '@angular/core';
import { axiosPost } from './api';

import {
  newMeetingMutation,
  checkAttendeId,
  checkMeetingId,
  updateUserPermissions,
  removeParticipant,
  addParticipantToMeeting,
  removeParticipantNotConnected,
  updateMeeting,
} from '../mutations/newMeeting';
import {
  getAllMeetings,
  getUserByMeetingId,
  getUserPermissionsByMeetingId,
  getMeetingByMeetingId,
  getMeetingsByUserId,
} from '../queries/meeting';

@Injectable({
  providedIn: 'root',
})
export class MeetingService {
  constructor() {}

  newMeeting(formValue: any) {
    try {
      return axiosPost(newMeetingMutation(formValue));
    } catch (error) {}
  }
  updateMeeting(formValue: any) {
    try {
      return axiosPost(updateMeeting(formValue));
    } catch (error) {}
  }
  checkAttendeId(formValue: any) {
    try {
      return axiosPost(checkAttendeId(formValue));
    } catch (error) {}
  }
  checkMeetingId(id: any) {
    try {
      return axiosPost(checkMeetingId(id));
    } catch (error) {}
  }

  getAllMeetings() {
    try {
      return axiosPost(getAllMeetings());
    } catch (error) {}
  }
  getMeetingsByUserId(email: string) {
    try {
      return axiosPost(getMeetingsByUserId(email));
    } catch (error) {}
  }
  getMeetingByMeetingId(id: any) {
    try {
      return axiosPost(getMeetingByMeetingId(id));
    } catch (error) {}
  }
  getUserByMeetingId(id: any) {
    try {
      return axiosPost(getUserByMeetingId(id));
    } catch (error) {}
  }
  getUserPermissionsByMeetingId(id: any) {
    try {
      return axiosPost(getUserPermissionsByMeetingId(id));
    } catch (error) {}
  }
  updateUserPermissions(user: any) {
    try {
      return axiosPost(updateUserPermissions(user));
    } catch (error) {}
  }
  removeParticipant(user: any) {
    try {
      return axiosPost(removeParticipant(user));
    } catch (error) {}
  }
  addParticipantToMeeting(user: any) {
    try {
      return axiosPost(addParticipantToMeeting(user));
    } catch (error) {}
  }
  removeParticipantNotConnected(user: any) {
    try {
      return axiosPost(removeParticipantNotConnected(user));
    } catch (error) {}
  }
}
