export function newMeetingMutation(obj: any) {
  let relatedEmail = '';
  if (obj.attendess.length > 0) {
    obj.attendess.forEach((element: any) => {
      relatedEmail += `{email: ${JSON.stringify(element)}},`;
    });
  }
  return `
  mutation{
    newMeeting(meetingId:"${obj.meetingId}"
    userId: ${obj.userId}
    title: "${obj.title}" 
    startTime: "${obj.startTime}"
    durationTime:{
      hour: ${obj.durationTime.hour}
      minutes:${obj.durationTime.minutes}
    }
    attendess:[${relatedEmail}]
    ){
      message
    }
  }`;
}
export function updateMeeting(obj: any) {
  let relatedEmail = '';
  if (obj.attendess.length > 0) {
    obj.attendess.forEach((element: any) => {
      relatedEmail += `{email: ${JSON.stringify(element)}},`;
    });
  }
  return `
  mutation{
    updateMeeting(meetingId:"${obj.meetingId}"
    userId: ${obj.userId}
    id:${obj.id}
    title: "${obj.title}" 
    startTime: "${obj.startTime}"
    durationTime:{
      hour: ${obj.durationTime.hour}
      minutes:${obj.durationTime.minutes}
    }
    attendess:[${relatedEmail}]
    ){
      message
    }
  }`;
}

export function checkAttendeId(obj: any) {
  return `mutation{
    checkAttendeId(attendeId:"${obj.id}"){
      attende{
        email
        attendeId
      }
    room{
      roomSid
      id
      url
      startTime
      durationTime{
        hour
        minutes
      }
    }
    }
  }`;
}
export function checkMeetingId(id: any) {
  return `mutation{
    checkMeetingId(meetingId:"${id}"){
     room{
      id
      meetingId
       User{
        fullName
        email
      }
      title
      startTime
      durationTime{
        hour
        minutes
      }
     }
     attendess{
      email
      meetingId
      id
      createdAt
    }
    }

  }`;
}

export function updateUserPermissions(user: any) {
  return `mutation{
    updateUserPermissions(meetingId:"${user.meetingId}"
    email:"${user.email}"
    permissions:{
      mute:${user.permissions.mute}
      video:${user.permissions.video}
      muteAll:${user.permissions.muteAll}
    }){
      message
    }
  }`;
}
export function removeParticipant(user: any) {
  return `mutation{
    removeParticipant(roomName:"${user.meetingId}"
    identity:"${user.identity}"){
      message
    }
  }`;
}
export function addParticipantToMeeting(user: any) {
  return `mutation{
    addParticipantToMeeting(meetingId:"${user.meetingId}"
    email:"${user.email}"){
      message
    }
  }`;
}
export function removeParticipantNotConnected(user: any) {
  return `mutation{
    removeParticipantNotConnected(meetingId:"${user.meetingId}"
    email:"${user.email}"){
      message
    }
  }`;
}
