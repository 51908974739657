import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { DashboardComponent } from '../app/dashboard/dashboard.component';
import { ShellComponent } from '../app/shell/shell.component';
import { ChatComponent } from '../app/chat/chat.component';
import { SetupProfileComponent } from '../app/setup-profile/setup-profile.component';
import { MeetingLeftComponent } from '../app/meeting-left/meeting-left.component';

import { KinderMeetingComponent } from '../app/kinder-meeting/kinder-meeting.component';

import { Authorization } from './helpers/authorization';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
  },
  {
    path: 'setup-profile',
    component: SetupProfileComponent,
  },
  {
    path: 'meeting',
    loadChildren: () => import('./meeting-module/meeting.module').then((m) => m.MeetingModule),
    canActivate: [Authorization],
  },
  {
    path: 'meet',
    loadChildren: () => import('./videocalling/video-calling.module').then((m) => m.VideoCallingModule),
    canActivate: [Authorization],
  },
  {
    path: 'left/:id',
    component: MeetingLeftComponent,
    canActivate: [Authorization],
  },
  {
    path: '',
    component: ShellComponent,
    canActivate: [Authorization],
    children: [
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'chat',
        component: ChatComponent,
      },

      {
        path: 'kinder-meeting',
        component: KinderMeetingComponent,
      },
    ],
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
