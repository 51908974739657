import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '@app/services/auth.service';
import { MeetingService } from '@app/services/meeting.service';
import { ToastrService } from 'ngx-toastr';
import { convertToObject } from 'typescript';
import countries from '../_files/allCountries.json';
import axios from 'axios';

@Component({
  selector: 'app-setup-profile',
  templateUrl: './setup-profile.component.html',
  styleUrls: ['./setup-profile.component.scss'],
})
export class SetupProfileComponent implements OnInit {
  countriesList: { country: string; state: [] }[] = countries;
  countries: any = [];
  isBillingAddress = false;
  states: any = [];
  loading = false;
  picLoading = false;
  profilePicUrl: any;

  onBoarding = {
    id: '',
    fullName: '',
    gender: 'Male',
    firstLanguage: '',
    otherLanguage: '',
    privacySetting: 'notPublish',
    contactPreferences: '',
    profilePicUrl: '',
    role: 'user',
    presonalAddress: {
      address: '',
      country: '',
      state: '',
      postal_code: '',
      type: 'personal',
    },
    billingAddress: {
      address: '',
      country: '',
      state: '',
      postal_code: '',
      type: 'billing',
    },
  };

  submitted: boolean = false;
  constructor(private authService: AuthService, private toastr: ToastrService, private meetingService: MeetingService) {
    var profile = JSON.parse(localStorage.getItem('profile'));
    this.onBoarding.id = profile.id;
    this.onBoarding.fullName = `${profile.firstName} ${profile.lastName}`;
  }
  ngOnInit(): void {}

  getAllSatate(event: any) {
    this.countriesList.map((country: any) => {
      if (country.country === event.value) {
        this.states = country.states;
      }
    });
  }

  submitForm(form: NgForm) {
    this.submitted = true;
    if (!form.valid) {
      return;
    }
    this.loading = true;
    this.authService
      .onBoardingUser(this.onBoarding)
      .then((data) => {
        if (data == undefined) {
          this.loading = false;
          return;
        } else {
          this.loading = false;
          this.toastr.success('User Onboarding complete.');
        }
      })
      .catch((err) => {});
  }
  sameAsAbove(form: any) {
    if (form.checked) {
      this.onBoarding.billingAddress = {
        address: this.onBoarding.presonalAddress.address,
        country: this.onBoarding.presonalAddress.country,
        state: this.onBoarding.presonalAddress.state,
        postal_code: this.onBoarding.presonalAddress.postal_code,
        type: 'billing',
      };
      this.isBillingAddress = true;
    } else {
      this.onBoarding.billingAddress = {
        address: '',
        country: '',
        state: '',
        postal_code: '',
        type: 'billing',
      };
      this.isBillingAddress = false;
    }
  }

  async handleFileInput(files: FileList) {
    // this.picLoading = true;
    // const formData = new FormData();
    // var file = files[0];
    // let operations: any = {
    //   query: `
    //     mutation($file: Upload!) {
    //       uploadFile(file: $file) {
    //         Key
    //         Location
    //       }
    //     }
    //   `,
    //   variables: {
    //     file: null,
    //   },
    // };
    // let _map = {
    //   file: ['variables.file'],
    // };
    // var fd = new FormData();
    // fd.append('operations', JSON.stringify(operations));
    // fd.append('map', JSON.stringify(_map));
    // fd.append('file', file);
    // this.authService.uploadImage(fd).then((res: any) => {
    //   if (res.data.errors) {
    //     this.picLoading = false;
    //     res.data.errors &&
    //       res.data.errors.map((err: any) => {
    //         this.toastr.error(err.message, 'Error');
    //       });
    //     return;
    //   }
    //   this.profilePicUrl = res.data.data.uploadFile.Location;
    //   this.onBoarding.profilePicUrl = this.profilePicUrl;
    //   this.picLoading = false;
    // });
  }
}
